import { Component, OnInit } from '@angular/core';
import { TrezieService } from '../../service/treize.service';
import { ViewportScroller } from '@angular/common';
import { Publication } from 'src/app/model/publication';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public publications:Array<Publication>;
  viewMore:boolean = false;
  loaded:boolean = true;

  constructor(private trezieService: TrezieService, private viewportScroller: ViewportScroller) { }

  ngOnInit() {
    this.trezieService.getPublicationsLast().subscribe(data => {
      this.publications=data;
      console.log(this.publications)
      this.loaded = false;
    });
  }

  hidePublishViewMore():void {
    this.viewMore = true;
    this.loaded = true;
    this.trezieService.getPublications().subscribe(data => {
      this.publications=data;
      this.loaded = false;
    });
    this.viewportScroller.scrollToAnchor('portfolio');
  }

  hidePublishViewMinus():void {
    this.viewMore = false;
    this.trezieService.getPublicationsLast().subscribe(data => {
      this.publications=data;
    });
    this.viewportScroller.scrollToAnchor('portfolio');
  }

}
