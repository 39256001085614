import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private viewportScroller: ViewportScroller) { }

  ngOnInit() {
  }

  onClickScoll(elementId: string):void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  @HostListener('window:scroll', ['$event'])

  onWindowScroll(e) {
      let element = document.querySelector('#mainNav');
      if (window.pageYOffset > element.clientHeight) {
        element.classList.add('navbar-scrolled');
      } else {
        element.classList.remove('navbar-scrolled');
      }
    }
}
