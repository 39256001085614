import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { TrezieService } from './service/treize.service';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ShopComponent } from './components/shop/shop.component';
import { MentionsLegalesComponent } from './components/mentions-legales/mentions-legales.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'mentions', component: MentionsLegalesComponent },
  { path: 'shop', component: ShopComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ShopComponent,
    MentionsLegalesComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    })
  ],
  providers: [TrezieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
