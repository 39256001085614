import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Publication } from '../model/publication';
import { HttpHeaders } from '@angular/common/http';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
    })
  };

@Injectable()
export class TrezieService {
  constructor(private http: HttpClient) { }



  getPublications() {
    // now returns an Observable of Config
    //return this.http.get<Publication>("http://localhost:4200/api/publications",httpOptions);
    return this.http.get<Array<Publication>>("https://api.1treize3.com/publications",httpOptions);
  }

  getPublicationsLast() {
    // now returns an Observable of Config
    //return this.http.get<Publication>("http://localhost:4200/api/publications/last",httpOptions);
    return this.http.get<Array<Publication>>("https://api.1treize3.com/publications/last",httpOptions);
  }
}
