import { Component, OnInit } from '@angular/core';
import { TrezieService } from '../../service/treize.service';
import { Accessoire } from '../../model/accessoire';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})
export class ShopComponent implements OnInit {

  accessoires:Array<Accessoire> =  [];

  constructor(private trezieService: TrezieService, private viewportScroller: ViewportScroller) { }

  ngOnInit() {
    this.accessoires.push(new Accessoire("Porte-Clefs","Porte-Clefs en plastique fait main, décoré à l'aide de marqueur. Chaque pièce est unique, plutôt que d'être numéroté elles sont nommées",7));
    this.accessoires.push(new Accessoire("Sacs","Sac imprimé à Mérignac, sac 100% coton ou toile de jute, ...",0));
  }

}
