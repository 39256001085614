export class Accessoire {
    nom:string;
    description:string;
    quantite:number;

    constructor(nom: string,description:string,quantite:number){
        this.nom = nom;
        this.description = description;
        this.quantite = quantite;
    }

}